<!--* View Section *-->
<template>
  <div id="Slider1">
    <!-- Start Main Banner Area -->
    <div class="main-banner-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
            <div class="main-banner-content-with-search mt-5">
              <h6
                style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 25px;
                "
              >
                Thank you for choosing <b>Noble Staffing Services!!</b>
                <br /><br />We pride ourselves on being the reliable platform
                that provides personalized and compassionate care services for
                individuals with special needs. Our compassionate caretakers are
                available 24/7 to offer you the well-versed support services
                tailored to your specific needs. <br />
                <br />
                Noble Staffing Services exists to provide outstanding disability
                assistance, wherever it is needed. Whether you're in need for
                mental health support, individual care or personal life skill
                development, We are your trusted platform for availing
                individualised support and nursing care services .
              </h6>
            </div>
          </div>

          <div class="col-lg-7 col-md-12">
            <div
              class="main-banner-image-with-doctor"
              data-speed="0.06"
              data-revert="true"
            >
              <img :src="womanDoctor" alt="doctor" />

              <div class="circle-pattern"></div>

              <!-- <div class="banner-card-content"> -->
              <!-- <div class="icon">
                  <i class="ri-phone-line"></i>
                </div>
                <h3>24/7 Available</h3>
                <p>Contact Us</p> -->
              <img :src="loveNdsis" alt="" />
              <!-- </div> -->

              <!-- <div class="banner-doctor-content">
                <div class="title-info">
                  <h3>Available Doctors</h3>
                  <p>Select Doctors</p>
                </div>

                <div class="doctor-info-box">
                  <div class="info-item">
                    <img :src="bannerOne1" alt="image" />
                    <h4>Thomas Albedin</h4>
                    <span>ISHRS - ABCOM</span>
                  </div>

                  <div class="info-item">
                    <img :src="bannerOne2" alt="image" />
                    <h4>Camden Bacon</h4>
                    <span>ISHRS - ABCOM</span>
                  </div>

                  <div class="info-item">
                    <img :src="bannerOne3" alt="image" />
                    <h4>Mia Denys</h4>
                    <span>ISHRS - ABCOM</span>
                  </div>

                  <div class="info-item">
                    <img :src="bannerOne4" alt="image" />
                    <h4>Marissa Swan</h4>
                    <span>ISHRS - ABCOM</span>
                  </div>

                  <div class="doctor-contact-btn">
                    <a href="contact.html" class="default-btn"
                      >Contact Doctor</a
                    >
                  </div>
                </div>
              </div> -->

              <!-- <div class="banner-image-shape-1">
                <img :src="bannerOneShape1" alt="image" />
              </div>

              <div class="banner-image-shape-2">
                <img :src="bannerOneShape2" alt="image" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Main Banner Area -->
  </div>
</template>


<!--* Logic Section *-->
<script>
//  Import Section  //

export default {
  //  My Route  //
  name: "Slider1",

  //  Components  //
  components: {},

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      loveNdsis: require("@/assets/images/love-ndis.png"),
      womanDoctor: require("@/assets/images/main-banner/banner-one/woman-doctor.png"),
      bannerOne1: require("@/assets/images/main-banner/banner-one/image-1.jpg"),
      bannerOne2: require("@/assets/images/main-banner/banner-one/image-2.jpg"),
      bannerOne3: require("@/assets/images/main-banner/banner-one/image-3.jpg"),
      bannerOne4: require("@/assets/images/main-banner/banner-one/image-4.jpg"),
      bannerOneShape1: require("@/assets/images/main-banner/banner-one/banner-shape-1.png"),
      bannerOneShape2: require("@/assets/images/main-banner/banner-one/banner-shape-2.png"),
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--* Style Section **-->
<style lang="scss" scoped>
#Slider1 {
  .main-banner-area {
    background-image: url("@/assets/images/main-banner/banner-one/banner-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
}
</style>
