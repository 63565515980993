<!--* View Section *-->
<template>
  <div id="Testimonials">
    <!-- Start Testimonials Area -->
    <div class="doctors-area bg-f5f6fb pt-100 pb-100">
      <div class="container">
        <div class="section-title">
          <p class="display-6 text-info fw-bold text-center">
            Our Testimonials
          </p>
        </div>

        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6"
            v-for="(data, index) in testimonialsData"
            :key="index"
          >
            <div class="single-doctors-card">
              <div class="doctors-image">
                <!-- <a><img :src="data.image" alt="image" /></a> -->
              </div>
              <div class="doctors-content">
                <h3>
                  <a>{{ data.name }}</a>
                </h3>
                {{ data.rate }}
                <div class="doc-location">
                  <p>
                    {{ data.review }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Testimonials Area -->
  </div>
</template>

<!--* Logic Section *-->
<script>
//  Import Section  //

export default {
  //  My Route  //
  name: "Testimonials",

  //  Components  //
  components: {},

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      doctor_small_3: require("@/assets/images/doctors/doctor-small-3.png"),
      doctor_small_2: require("@/assets/images/doctors/doctor-small-2.png"),
      testimonialsData: [
        {
          name: "Anita",
          rate: "⭐⭐⭐⭐",
          review:
            "I would like to express my heartfelt appreciation for the exceptional care and support provided by the Noble Staffing Services. Their dedication and commitment to ensuring the well-being and happiness of individuals with disabilities is truly commendable.",
          image: require("@/assets/images/doctors/doctor-small-2.png"),
        },
        {
          name: "Peter",
          rate: "⭐⭐⭐⭐",
          review:
            "I am extremely grateful for the outstanding care provided by the Noble Staffing Services. Their commitment and dedication to ensuring the well-being and happiness of my loved one with disabilities have been exceptional. They possess an incredible ability to connect with individuals, creating a warm and supportive environment.",
          image: require("@/assets/images/doctors/doctor-small-3.png"),
        },
        {
          name: "Kylie",
          rate: "⭐⭐⭐⭐⭐",
          review:
            "I cannot express enough how thankful I am for the Noble Staffing Services exceptional services. Their team has consistently gone above and beyond to provide personalized care and support to my family member with special needs. From assisting with daily activities to promoting social engagement and independence.",
          image: require("@/assets/images/doctors/doctor-small-2.png"),
        },
      ],
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--* Style Section **-->
<style lang="scss" scoped></style>
