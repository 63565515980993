<!--* View Section *-->
<template>
  <div id="whychooseus">
    <!-- Start Why Choose Area -->
    <div class="why-choose-area ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="choose-fun-fact-item">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-5 box-1">
                  <a
                    href="https://www.ndis.gov.au/understanding/what-ndis"
                    target="_blank"
                    style="text-decoration: none"
                  >
                    <div class="choose-fun-fact box-1">
                      <h3>
                        <span style="color: #6a2875">What is the NDIS?</span>
                      </h3>
                    </div>
                  </a>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <router-link to="/about" style="text-decoration: none">
                    <div class="choose-fun-fact bg-ef720c box-2">
                      <h3>
                        <span>Our Team</span>
                      </h3>
                    </div>
                  </router-link>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <router-link to="/contact" style="text-decoration: none">
                    <div class="choose-fun-fact bg-ed2f16 box-3">
                      <h3>
                        <span class="odometer" style="color: darkslateblue"
                          >24/7</span
                        >
                        <p style="font-size: 30px">Available Services</p>
                      </h3>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="why-choose-content">
              <p class="display-6 text-info fw-bold">What Makes Us Unique</p>

              <abbr style="font-size: 20px; font-family: Arial">
                We provide excellent participant services, giving you
                reassurance that we are committed to maintaining the quality &
                safety standards set by the NDIS and deliver the best NDIS
                services.<br /><br />
              </abbr>

              <ul
                class="info-list"
                style="list-style: none; font-size: 20px; line-height: 1.8"
              >
                <li>✔ 100% Australian Owned & Operated Company</li>
                <li>
                  ✔ We have a team of skilled & trained Disability support
                  workers, registered nurses, and enrolled nurses.
                </li>
                <li>✔ 24/7 Support</li>
                <li>✔ Experience & Expertise</li>
                <li>✔ High-Quality Care</li>
                <li>✔ Easy Process</li>
                <li>✔ Individualised Care Plans</li>
                <li>
                  ✔ With hundreds of Support Workers from Australia and around
                  the World, we can offer many languages and cultural
                  backgrounds
                </li>
              </ul>

              <div class="why-choose-btn">
                <router-link
                  to="/contact"
                  style="text-decoration: none"
                  class="default-btn"
                  >Contact Us</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="why-choose-shape-1" data-speed="0.08" data-revert="true">
        <img :src="chooseShape1" alt="image" />
      </div>

      <div class="why-choose-shape-2" data-speed="0.08" data-revert="true">
        <img :src="chooseShape2" alt="image" />
      </div> -->
    </div>
    <!-- End Why Choose Area -->
  </div>
</template>


<!--* Logic Section *-->
<script>
//  Import Section  //

export default {
  //  My Route  //
  name: "whychooseus",

  //  Components  //
  components: {},

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      chooseShape1: require("@/assets/images/why-choose/shape-1.png"),
      chooseShape2: require("@/assets/images/why-choose/shape-2.png"),
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--* Style Section **-->
<style lang="scss" scoped>
#whychooseus {
  .box-3 {
    background-color: #e2f6ca;
  }

  .box-2 {
    background-color: #9be8d8;
  }
  /* .box-1 {
    background-color: #78c1f3;
  } */
}
</style>
