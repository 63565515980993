<!--* View Section *-->
<template>
  <div id="Header">
    <div class="mobile-navbar">
      <b-navbar class="p-2" toggleable="sm" type="light" variant="light">
        <b-navbar-brand class="m-3">
          <img :src="logo" class="black-logo" alt="image"
        /></b-navbar-brand>
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav class="m-3">
            <b-nav-item class="nav-link"
              ><router-link to="/">Home</router-link></b-nav-item
            >
            <b-nav-item
              ><router-link to="/about">About Us</router-link></b-nav-item
            >
            <b-nav-item-dropdown text="Services" right>
              <b-dropdown-item
                ><router-link to="/service-1" class="nav-link"
                  >Assistance with Daily Life
                </router-link></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/service-3" class="nav-link"
                  >Community Nursing Care</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/service-2" class="nav-link"
                  >Assistance with Social and Community Services</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/service-4" class="nav-link"
                  >Domestic Care and Cleaning</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/service-7" class="nav-link"
                  >Companionship and Community Access</router-link
                ></b-dropdown-item
              >
              <b-dropdown-item
                ><router-link to="/service-6" class="nav-link"
                  >Short/Long Term Respite Care
                </router-link></b-dropdown-item
              >
              <b-dropdown-item>
                <router-link to="/service-7" class="nav-link"
                  >Companionship and Community Access</router-link
                >
              </b-dropdown-item>

              <b-dropdown-item>
                <router-link to="/service-5" class="nav-link"
                  >High Intensity Daily Living</router-link
                >
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/service-8" class="nav-link"
                  >Travel and Transportation</router-link
                >
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/service-11" class="nav-link"
                  >Accommodation</router-link
                >
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item
              ><router-link to="/careers">Careers</router-link></b-nav-item
            >
            <b-nav-item
              ><router-link to="/referrals">Referrals</router-link></b-nav-item
            >
            <b-nav-item
              ><router-link to="/contact">Contact Us</router-link></b-nav-item
            >
            <b-nav-item
              ><router-link to="/login">Login</router-link></b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- Start Navbar Area -->
    <div class="navbar-area fixed-top">
      <div class="main-responsive-nav">
        <div class="container">
          <div class="main-responsive-menu">
            <div class="logo">
              <a href="index.html">
                <img :src="logo" class="black-logo" alt="image" />
                <img :src="whiteLogo" class="white-logo" alt="image" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="main-navbar">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link to="/" class="navbar-brand">
              <img :src="logo" class="black-logo" alt="image" />
              <img :src="whiteLogo" class="white-logo" alt="image" />
            </router-link>

            <div
              class="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav m-auto">
                <li class="nav-item">
                  <router-link to="/" class="nav-link active">
                    Home
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/about" class="nav-link"
                    >About Us</router-link
                  >
                </li>

                <li class="nav-item">
                  <router-link to="" class="nav-link">
                    Services
                    <i class="ri-arrow-down-s-line"></i>
                  </router-link>

                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/service-1" class="nav-link"
                        >Assistance with Daily Life
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-3" class="nav-link"
                        >Community Nursing Care</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-2" class="nav-link"
                        >Assistance with Social and Community
                        Services</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-4" class="nav-link"
                        >Domestic Care and Cleaning</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-7" class="nav-link"
                        >Companionship and Community Access</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-5" class="nav-link"
                        >High Intensity Daily Living</router-link
                      >
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="/service-6" class="nav-link"
                        >Short/Long Term Respite Care</router-link
                      >
                    </li> -->

                    <li class="nav-item">
                      <router-link to="/service-8" class="nav-link"
                        >Travel and Transportation</router-link
                      >
                    </li>
                    <li class="nav-item">
                      <router-link to="/service-11" class="nav-link"
                        >Accommodation</router-link
                      >
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="" class="nav-link"
                        >Supported Independent Living (SIL)
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="" class="nav-link"
                        >Support Coordination
                      </router-link>
                    </li> -->
                  </ul>
                </li>

                <li class="nav-item">
                  <router-link to="/careers" class="nav-link">
                    Careers
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/referrals" class="nav-link">
                    Referrals
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/contact" class="nav-link">
                    Contact Us
                  </router-link>
                </li>
              </ul>

              <div class="others-options d-flex align-items-center">
                <div class="option-item">
                  <div class="call-info">
                    <i class="ri-phone-line"></i>
                    <a href="tel:+0491714319">0493 525 749</a>
                  </div>
                </div>

                <!-- <div class="option-item">
                  <router-link
                    to="/login"
                    style="text-decoration: none"
                    class="default-btn"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 21v-2h7V5h-7V3h7q.825 0 1.413.588T21 5v14q0 .825-.588 1.413T19 21h-7Zm-2-4l-1.375-1.45l2.55-2.55H3v-2h8.175l-2.55-2.55L10 7l5 5l-5 5Z"
                      /></svg
                    >Login</router-link
                  >
                </div> -->
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div class="others-option-for-responsive">
        <div class="container">
          <div class="dot-menu">
            <div class="inner">
              <div class="circle circle-one"></div>
              <div class="circle circle-two"></div>
              <div class="circle circle-three"></div>
            </div>
          </div>

          <div class="container">
            <div class="option-inner">
              <div class="others-options d-flex align-items-center">
                <div class="option-item">
                  <div class="call-info">
                    <i class="ri-phone-line"></i>
                    <a href="tel:00123654878">+00 123 654 878</a>
                  </div>
                </div>

                <div class="option-item">
                  <router-link to="" class="default-btn"
                    >Book Appointment</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->
  </div>
</template>


<!--* Logic Section *-->
<script>
//  Import Section  //
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BNavbar,
  BNavbarToggle,
  BNavbarNav,
  BNavText,
  BCollapse,
  BNavItem,
  BNavbarBrand,
} from "bootstrap-vue";

export default {
  //  My Route  //
  name: "Header",

  //  Components  //
  components: {
    BNavbar,
    BNavbarToggle,
    BNavbarNav,
    BNavText,
    BCollapse,
    BNavItem,
    BNavbarBrand,
  },

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      logo: require("@/assets/images/footerLogo.png"),
      whiteLogo: require("@/assets/images/footerLogoWhite.png"),
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--* Style Section **-->
<style lang="scss" scoped>
#Header {
  .black-logo {
    width: 150px;
  }
  .nav-link {
    font-size: 20px;
  }
  .white-logo {
    width: 150px;
  }
  .mobile-navbar {
    display: none !important;
  }
  .main-navbar {
    display: block !important;
  }
  @media screen and (max-width: 768px) {
    .mobile-navbar {
      /* background-color: lightgreen; */
      margin: 0;
      padding: 0;
      display: block !important;
    }
    .navbar-area {
      display: none !important;
    }
  }
}
</style>
