import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('@/views/Careers.vue')
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('@/views/Referrals.vue')
  },
  {
    path: '/service-1',
    name: 'service-1',
    component: () => import('@/views/services/Service-1.vue')
  },
  {
    path: '/service-2',
    name: 'service-2',
    component: () => import('@/views/services/Service-2.vue')
  },
  {
    path: '/service-3',
    name: 'service-3',
    component: () => import('@/views/services/Service-3.vue')
  },
  {
    path: '/service-4',
    name: 'service-4',
    component: () => import('@/views/services/Service-4.vue')
  },
  {
    path: '/service-5',
    name: 'service-5',
    component: () => import('@/views/services/Service-5.vue')
  },
  {
    path: '/service-6',
    name: 'service-6',
    component: () => import('@/views/services/Service-6.vue')
  },
  {
    path: '/service-7',
    name: 'service-7',
    component: () => import('@/views/services/Service-7.vue')
  },
  {
    path: '/service-8',
    name: 'service-8',
    component: () => import('@/views/services/Service-8.vue')
  },
  {
    path: '/service-9',
    name: 'service-9',
    component: () => import('@/views/services/Service-9.vue')
  },
  {
    path: '/service-10',
    name: 'service-10',
    component: () => import('@/views/services/Service-10.vue')
  },
  {
    path: '/service-11',
    name: 'service-11',
    component: () => import('@/views/services/Service-11-Accommodation.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/components/Toastification.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
