<!--* View Section *-->
<template>
  <div id="Footer">
    <!-- Start Footer Area -->
    <footer class="footer-area pt-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-6">
            <div class="single-footer-widget">
              <div class="widget-logo">
                <img :src="logo" class="black-logo" alt="image" />
                <img :src="whiteLogo" class="white-logo" alt="image" />
              </div>

              <p>
                We pride ourselves on being the reliable platform that provides
                personalized and compassionate care services for individuals
                with special needs. Our compassionate caretakers are available
                24/7 to offer you the well-versed support services tailored to
                your specific needs.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget ps-5">
              <h3>Useful Links</h3>

              <ul class="quick-links">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li>
                  <router-link to="/about">About Us</router-link>
                </li>
                <li>
                  <router-link to="/careers">Careers</router-link>
                </li>
                <li>
                  <router-link to="/referrals">Referrals</router-link>
                </li>
                <li>
                  <router-link to="/contact">Contact Us</router-link>
                </li>
                <li>
                  <router-link to="/login">Login</router-link>
                </li>
                <li>
                  <router-link to="/register">Register</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget">
              <h3>Services</h3>
              <ul class="quick-links">
                <li>
                  <router-link to="/service-1" class="nav-link"
                    >Assistance with Daily Life
                  </router-link>
                </li>
                <li>
                  <router-link to="/service-3" class="nav-link"
                    >Community Nursing Care</router-link
                  >
                </li>
                <li>
                  <router-link to="/service-2" class="nav-link"
                    >Assistance with Social and Community Services</router-link
                  >
                </li>
                <li>
                  <router-link to="/service-4" class="nav-link"
                    >Domestic Care and Cleaning</router-link
                  >
                </li>
                <li>
                  <router-link to="/service-7" class="nav-link"
                    >Companionship and Community Access</router-link
                  >
                </li>
                <li>
                  <router-link to="/service-5" class="nav-link"
                    >High Intensity Daily Living</router-link
                  >
                </li>
                <!-- <li>
                      <router-link to="/service-6" class="nav-link"
                        >Short/Long Term Respite Care</router-link
                      >
                    </li> -->

                <li>
                  <router-link to="/service-8" class="nav-link"
                    >Travel and Transportation</router-link
                  >
                </li>
                <li>
                  <router-link to="/service-11" class="nav-link"
                    >Accommodation</router-link
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget ps-3">
              <h3>Contact Information</h3>

              <ul class="footer-information">
                <li>
                  <i class="ri-phone-fill"></i>
                  <h4><a href="tel:0493525749">0493 525 749</a></h4>
                  <span>Contact Us</span>
                </li>

                <li>
                  <i class="ri-time-line"></i>
                  <h4>24/7</h4>
                  <span>Open Hours</span>
                </li>

                <!-- <li>
                  <i class="ri-map-pin-line"></i>
                  <h4>27 Scott street, Melton, VIC-3337</h4>
                  <span>Our Location</span>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.818812814052!2d144.5727203767996!3d-37.67696502623366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6ed19c7779c19%3A0x7c7d91317933a288!2s27%20Scott%20St%2C%20Melton%20VIC%203337%2C%20Australia!5e0!3m2!1sen!2sin!4v1688811433744!5m2!1sen!2sin"
                    width="200"
                    height="150"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <abbr>
          <span> <img :src="flag" />&nbsp; </span><br />
          <a
            style="text-decoration: none"
            href="https://noblestaffingservices.com.au/"
            ><strong>&nbsp;Noble Staffing Services Pty Ltd. &nbsp;</strong></a
          >believe that diversity, equity and inclusion are at the core of who
          we are. We embrace everyone regardless of age, gender identity, race,
          sexual orientation, physical or mental ability or ethnicity. Our
          commitment to these values is unwavering. Further, we recognise the
          objectives of the Convention on the Rights of Persons with
          Disabilities. We also acknowledge the traditional custodians of
          country throughout Australia, and their continuing connection to land,
          sea and community. We pay our respects to them and their cultures, and
          to elders past and present. We are committed to collaboration that
          furthers self-determination and creates a better future for all.
        </abbr>
      </div>
      <div class="copyright-area">
        <div class="container">
          <div class="copyright-area-content">
            <p>
              Copyright @ {{ currentYear }} All Rights Reserved by
              <a
                style="text-decoration: none"
                href="https://noblestaffingservices.com.au/"
              >
                Noble Staffing Services
              </a>

              <!-- <a
                style="text-decoration: none"
                href="https://in.linkedin.com/in/chetan-bedi-593b11197"
                target="_blank"
              >
                &nbsp;| Developed By 👩‍💻 Chetan Bedi
              </a> -->
            </p>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer Area -->
  </div>
</template>
  
  
  <!--* Logic Section *-->
  <script>
//  Import Section  //

export default {
  //  My Route  //
  name: "Footer",

  //  Components  //
  components: {},

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      flag: require("@/assets/images/flag.png"),
      logo: require("@/assets/images/footerLogo.png"),
      whiteLogo: require("@/assets/images/footerLogoWhite.png"),
      footer1: require("@/assets/images/footer/footer-1.jpg"),
      signature: require("@/assets/images/footer/signature.png"),
      currentYear: new Date().getFullYear(),
    };
  },

  //  Functions  //
  methods: {},
};
</script>
  
  <!--* Style Section **-->
<style lang="scss" scoped>
#Footer {
  .black-logo {
    width: 150px;
  }
  .white-logo {
    width: 150px;
  }
  .footer-area {
    background-image: url("@/assets/images/footer/footer-bg.jpg") !important;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .single-footer-widget .footer-widget-blog .item .thumb .fullimage.bg1 {
    background-image: url("@/assets/images/footer/footer-2.jpg");
  }

  .single-footer-widget .footer-widget-blog .item .thumb .fullimage.bg2 {
    background-image: url("@/assets/images/footer/footer-3.jpg");
  }

  .single-footer-widget .footer-widget-blog .item .thumb .fullimage.bg3 {
    background-image: url("@/assets/images/footer/footer-4.jpg");
  }
}
</style>
  