<!--* View Section *-->
<template>
  <div id="ourmission">
    <!-- Our Mission Area -->
    <div class="healthcare-doctor-area ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="healthcare-doctor-content">
              <p class="display-6 text-info fw-bold">Our Mission</p>
              <!-- <h3>
                We have the certified nursing staff to take care of our clients
              </h3> -->
              <abbr style="font-size: 20px">
                Our mission is to provide quality care to our clients that they
                deserve because we care for our community and our clients
                well-being matters. We are a team who are passionate to provide
                great services to our clients and also to ensure our staff’s
                well- being. </abbr
              ><br /><br />
              <ul
                class="info-list"
                style="list-style: none; font-size: 20px; line-height: 1.8"
              >
                <p class="display-6 text-success fw-bold">Our Values</p>
                <li>✔ Integrity</li>
                <li>✔ Commitment to excellence</li>
                <li>✔ Consumer focus</li>
                <li>✔ Accountability</li>
                <li>✔ Inclusiveness</li>
                <li>✔ Diversity</li>
                <li>✔ Equality</li>
              </ul>

              <div class="healthcare-btn">
                <a
                  href="tel:0493525749"
                  style="text-decoration: none"
                  class="default-btn"
                >
                  <i class="ri-phone-line"></i>
                  &nbsp;&nbsp;Get in Touch</a
                >
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="healthcare-doctor-image align-self-center">
              <img :src="doctor1" alt="image" />

              <!-- <div class="circle-pattern"></div> -->

              <!-- <div class="healthcare-card-content">
                <div class="icon">
                  <i class="ri-time-line"></i>
                </div>
                <h3>24/7</h3>
                <p>Availability Anytime</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Our Mission Area -->
  </div>
</template>


<!--* Logic Section *-->
<script>
//  Import Section  //

export default {
  //  My Route  //
  name: "ourmission",

  //  Components  //
  components: {},

  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      doctor1: require("@/assets/images/healthcare-doctor/doctor-1.png"),
      stethoscope: require("@/assets/images/healthcare-doctor/stethoscope.png"),
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--* Style Section **-->
<style lang="scss" scoped>
</style>
