<template>
  <div class="home">
    <Header />
    <Slider1 />
    <WhyChooseUs />
    <!-- Start Services Area -->
    <div class="services-area pt-100 pb-75">
      <div class="container">
        <div class="section-title">
          <p class="display-6 text-info fw-bold text-center">Our Services</p>
        </div>
        <h2 class="mb-5" style="font-family: Arial">
          Our team is dedicated to empowering participants with increased
          autonomy and freedom of choice.
        </h2>
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 col-sm-6"
            v-for="(data, index) in serviceData"
            :key="index"
          >
            <div class="single-services-card">
              <div class="image">
                <img :src="data.image" alt="image" />
              </div>

              <div class="content">
                <h3>
                  <router-link :to="data.link" style="text-decoration: none">{{
                    data.name
                  }}</router-link>
                </h3>
                <p>
                  {{ data.description }}
                </p>
              </div>

              <div class="services-shape-1">
                <img :src="shape1" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Services Area -->
    <OurMission />
    <Testimonials />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import Slider1 from "@/views/slider/Slider1.vue";
import Testimonials from "@/views/home/Testimonials.vue";
import WhyChooseUs from "@/views/home/WhyChooseUs.vue";
import OurMission from "./home/OurMission.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
    Slider1,
    Testimonials,
    WhyChooseUs,
    OurMission,
  },
  //  Init  //
  mounted() {},

  //  Variables  //
  props: [""],

  data() {
    return {
      shape1: require("@/assets/images/services/shape-1.png"),
      serviceData: [
        {
          image: require("@/assets/images/services/service1.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `At Noble Staffing Services, you will get a dedicated caregiver with whom you will feel safe and supported all the time within the comfort of your own home. Our caregivers put their best foot forward to help the clients carry out various household...
`,
          name: "Assistance with Daily Life",
          link: "service-1",
        },
        {
          image: require("@/assets/images/services/service2.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `At Noble Staffing Services, Community Nursing Care is provided to participants who has complex individual support needs that are best met by someone who has medical training and experience to meet the participants’ complex health needs...`,
          name: "Community Nursing Care",
          link: "service-3",
        },
        {
          image: require("@/assets/images/services/service3.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `Noble Staffing Services provides a safe and inclusive environment for innovative community participation. Community involvement programs help you boost your self-esteem and create a sense of belonging... `,
          name: "Assistance with Social and Community Services",
          link: "service-2",
        },
        {
          image: require("@/assets/images/services/service4.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `Noble Staffing Services delivers a complete Domestic Care and Cleaning services. Understanding that each participant has their own unique needs, we recognise the importance of flexibility.By learning more about you and your situation...`,
          name: "Domestic Care and Cleaning",
          link: "service-4",
        },
        {
          image: require("@/assets/images/services/service5.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `Noble Staffing Services do the best that we can to care and support our participants.
Our Carers provide healthy social interactions and activities that promote good physical and cognitive health.`,
          name: "Companionship and Community Access",
          link: "service-7",
        },
        {
          image: require("@/assets/images/services/service6.jpg"),
          icon: require("@/assets/images/services/shape-1.png"),
          description: `Noble Staffing Services specialises in High Intensity supports for personal care and community participation. As a provider of High-Intensity support services, Our staff has competent skills to provide support under clinical guidance from our team`,
          name: "High Intensity Daily Living",
          link: "service-5",
        },
        // {
        //   image: require("@/assets/images/services/service7.jpg"),
        //   icon: require("@/assets/images/services/shape-1.png"),
        //   description: `We understand the importance of human connection and all our community activities are designed to bolster companionship among people with specific needs for leading a healthy and a joyful life.`,
        //   name: "Travel and Transportation",
        // },
      ],
    };
  },

  //  Functions  //
  methods: {},
};
</script>
<style>
.testimonials-area {
  background-image: url("@/assets/images/testimonials/testimonials-bg-1.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* @/assets/images/services/services-bg-1.jpg */
.services-area {
  background-image: url("@/assets/images/services/services-bg-1.jpg") !important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  margin-left: 50px;
  margin-right: 50px;
}
</style>
